.residential-treatment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
}

.main-title {
  text-align: center;
  color: #2c3e50;
  font-size: 2em;
  margin-bottom: 20px;
}

.paragraph {
  text-align: justify;
  margin-bottom: 20px;
}

.sub-title {
  color: #2980b9;
  font-size: 1.5em;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.bullet-points {
  margin-left: 20px;
  margin-bottom: 20px;
}

.bullet-points li {
  margin-bottom: 10px;
  color: #34495e;
}

.strong {
  color: #34495e;
}

/* Responsive Design */
@media (max-width: 768px) {
  .residential-treatment-container {
    padding: 10px;
  }

  .main-title {
    font-size: 1.5em;
  }

  .sub-title {
    font-size: 1.2em;
  }

  .paragraph, .bullet-points li {
    font-size: 14px;
  }
}

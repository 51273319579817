.blog-card {
 
  border-radius: 8px;
  overflow: hidden;
  margin: 16px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  flex: 0 0 calc(33.33% - 32px); 
  height: 100%;
}

.blog-card:hover {
  transform: scale(1.05);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-details {
  padding: 16px;
}

.blog-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.blog-author {
  font-style: italic;
  color: #555;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.blog-link {
  text-decoration: none;
  color: inherit; /* Preserve the link color */
}
/* Footer styles */
.footer {
    background-color: #151515;
    padding: 70px 0;
    color: #FFF;
    text-align: center;
}

.footer_container {
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-col {
    padding: 0 15px;
    margin-bottom: 30px;
    /* Ensure text is centered */
    flex: 1; /* Make sure columns take up equal space */
    min-width: 250px; /* Ensure columns have a minimum width */
}

.footer-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    margin: 0.5rem;
}

.footer-col ul {
    list-style-type: none; /* Remove default list style */
    padding: 0; /* Remove default padding */
}

.footer-col ul li {
    margin-bottom: 10px;
}

.footer-col ul li a {
    color: #DDD;
    font-size: 1rem;
    font-weight: 300;
    text-decoration: none;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content */
}

.footer-col ul li a i {
    margin-right: 10px; /* Adjust this value for the desired space */
}

.footer-col ul li a:hover {
    color: #FFF;
    padding-left: 7px;
}

.social-links {
    display: flex;
    justify-content: left; /* Center social links */
    flex-wrap: wrap;
    margin-top: 2rem;
}

.social-links a {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 5px; /* Use margin instead of margin-right and margin-bottom */
    transition: all 0.5s ease;
}

.social-links a:hover {
    color: #151515;
    background-color: #FFF;
}

@media (max-width: 767px) {
    .footer-col {
        width: 50%;
        text-align: center;
    }
}

@media (max-width: 574px) {
    .footer-col {
        width: 100%;
        text-align: center;
    }
}

.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
}

@media (max-width: 768px) {
    /* Responsive styles for smaller screens */
    .copyright {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
}

.small-container-footer{
    margin: 0.5rem;
    font-weight: 500;
}
.small-PrivacyPolicy{
    margin-top: 1rem;
    font-weight: 1000;
    font-size: 20px;
}
.fas{
    margin-right: 1rem;
}
.footer-adress{
    font-size: 12px;
    font-weight: 100;
}

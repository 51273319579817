.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.filter {
  margin-bottom: 10px;
}

.filter label {
  margin-right: 10px;
}

.filter select {
  padding: 5px;
  font-size: 14px;
}

.sessions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.sessions-table th,
.sessions-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.sessions-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333; /* Darken the text color */
}

.sessions-table td {
  vertical-align: middle;
}

.sessions-table td button {
  padding: 8px 12px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sessions-table td button:hover {
  background-color: #c82333;
}

.navbarClass {
    padding-left: 10%;
  }
  
  .middleNavBar {
    gap: 20px; /* Adjust gap for better spacing */
    flex-wrap: wrap; /* Allow wrapping to prevent overflow */
  }
  
  .navbar-toggler {
    margin-right: 10%;
  }
  
  .navbar-collapse {
    justify-content: center;
    align-items: center;
  }
  
  .my-sm-0 {
    margin-right: 200px;
  }
  
  .Logo {
    width: 100px;
    height: 100px;
  }
  
  .contactUsBtn {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
  }
  
  .mkAppointment {
    display: none;
  }
  
  @media (max-width: 991px) {
    .contactUsBtn {
      background-color: black;
      display: none;
    }
  
    .mkAppointment {
      display: block;
    }
  }
  
  /* Dropdown menu styles */
  .dropdown-menu {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    display: none; /* Hide by default */
    z-index: 1000; /* Ensure dropdown appears above other content */
    border-radius: 5px;
    padding: 10px 0;
    min-width: 200px;
  }
  
  .dropdown-menu.show {
    display: block; /* Show dropdown when .show class is applied */
  }
  
  .dropdown-item {
    color: #333333; /* Text color */
    padding: 10px 20px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #f8f9fa; /* Light gray background on hover */
    color: #007bff; /* Text color on hover */
    text-decoration: none;
  }
  
  .dropdown-divider {
    height: 1px;
    margin: 5px 0;
    background-color: #cccccc; /* Divider line color */
  }
  
  .dropdown-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .dropdown-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  /* Navbar links and general styles */
  .navbar-nav .nav-item {
    margin-right: 10px; /* Adjust spacing between navbar items */
  }
  
  .navbar-nav .nav-item:last-child {
    margin-right: 0; /* Remove margin for last navbar item */
  }
  
  @media (max-width: 767px) {
    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar-nav .nav-item {
      margin-right: 0; /* Remove margin for mobile view */
      margin-bottom: 10px; /* Add spacing between items */
    }
  
    .navbar-nav .nav-item:last-child {
      margin-bottom: 0; /* Remove margin for last item */
    }
  }
  
.residential-treatment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

.main-title {
  text-align: center;
  color: #2c3e50;
  font-size: 2em;
  margin-bottom: 20px;
}

.paragraph {
  text-align: justify;
  margin-bottom: 20px;
}

section {
  margin-bottom: 20px; /* Adjusted margin-bottom from 40px to 20px */
}

.section-title, .sub-title {
  color: #2980b9;
  font-size: 1.5em;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.list-item {
  margin-left: 20px;
  margin-bottom: 10px;
  color: #34495e;
}

.strong {
  color: #34495e;
}

.closing-remark {
  text-align: center;
  font-style: italic;
  margin-top: 40px;
  color: #7f8c8d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .residential-treatment-container {
    padding: 10px;
  }

  .main-title {
    font-size: 1.5em;
  }

  .section-title, .sub-title {
    font-size: 1.2em;
  }

  .paragraph, .list-item {
    font-size: 14px;
  }

  .closing-remark {
    font-size: 12px;
  }
}

.single-blog {
  width: 80%; /* Adjust width as needed */
  margin: 0 auto; /* Center the content */
  padding: 20px;
 /* Light background color */
 background-color: rgb(255, 255, 255);
 border: #000000;
  border-radius: 5px; /* Rounded corners */
}

.single-blog h2 {
  font-size: 2em; /* Larger heading */
  margin-bottom: 10px;
  text-align: center; /* Center the title */
}

.blog-info {
  display: flex; /* Arrange author and date information side-by-side */
  justify-content: space-between; /* Align items at the opposite ends */
  margin-bottom: 15px;
}

.blog-info p {
  font-size: 0.8em; /* Smaller text for author and date */
  color: #888; /* Light gray for text */
  margin: 0; /* Remove default margin */
}

.single-blog img {
  width: 100%; /* Display image full width */
  margin-bottom: 15px;
}

.blog-description {
  font-size: 1em; /* Regular text size for content */
  line-height: 1.5; /* Increase line spacing for readability */
  margin-bottom: 20px;
}

/* Optional styles for potentially improved readability */

.blog-description p {
  margin-bottom: 10px; /* Space between paragraphs */
}

.blog-description a {
  color: #333; /* Darker color for links */
  text-decoration: underline; /* Underline links */
}
/* Checkout.css */

.checkout-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.title1 {
  color: #ffffff;
}

form {
  display: grid;
  gap: 20px;
}

label {
  font-size: 16px;
  color: #333;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}


/* AdminDashboard.css */

.admin-dashboard {
  text-align: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.dashboard-card {
  text-decoration: none;
  color: inherit;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.3s ease;
}

.dashboard-card:hover {
  background-color: #f0f0f0;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 16px;
  color: #555;
  text-align: center;
}

.logout-div {
  margin-top: 30px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #ff5a5f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.container {
  margin: 20px;
}

.ant-space {
  display: flex;
  align-items: center;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-modal-body {
  text-align: center;
}

.internships-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.internships-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #34495e;
  font-weight: 700;
}

.program-details {
  margin-bottom: 40px;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.program-details h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2980b9;
  font-weight: 600;
}

.module h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #2c3e50;
  font-weight: 600;
}

.module ul {
  list-style-type: none;
  padding: 0;
}

.module ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.module ul li::before {
  content: "✔";
  color: #27ae60;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.payment-info, .program-fees {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.payment-info ul, .program-fees ul {
  list-style-type: none;
  padding: 0;
}

.payment-info ul li, .program-fees ul li {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.payment-info ul li, .program-fees ul li {
  margin-bottom: 5px;
}

.payment-info h2, .program-fees h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2980b9;
  font-weight: 600;
}

.payment-info strong {
  color: #2980b9;
}

/* Responsive design */
@media (max-width: 768px) {
  .internships-container {
    padding: 15px;
  }

  .internships-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .program-details h2 {
    font-size: 1.8em;
  }

  .module h3 {
    font-size: 1.3em;
  }

  .payment-info ul li, .program-fees ul li {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .internships-container {
    padding: 10px;
  }

  .internships-container h1 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .program-details h2 {
    font-size: 1.5em;
  }

  .module h3 {
    font-size: 1.1em;
  }

  .payment-info ul li, .program-fees ul li {
    font-size: 0.9em;
  }
}

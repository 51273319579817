.diploma-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.diploma-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #34495e;
  font-weight: 700;
}

.diploma-program {
  margin-bottom: 40px;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.diploma-program h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2980b9;
  font-weight: 600;
}

.program-module h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #2c3e50;
  font-weight: 600;
}

.program-module ul {
  list-style-type: none;
  padding: 0;
}

.program-module ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.program-module ul li::before {
  content: "✔";
  color: #27ae60;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.diploma-details {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.details-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details-content p {
  font-size: 1.2em;
  margin: 5px 0;
}

.details-content ul {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}

.details-content ul li {
  margin-bottom: 5px;
  font-size: 1.2em;
}

.details-content strong {
  color: #2980b9;
}

/* Responsive design */
@media (max-width: 768px) {
  .diploma-container {
    padding: 15px;
  }

  .diploma-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .diploma-program h2 {
    font-size: 1.8em;
  }

  .program-module h3 {
    font-size: 1.3em;
  }

  .details-content p, .details-content ul li {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .diploma-container {
    padding: 10px;
  }

  .diploma-container h1 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .diploma-program h2 {
    font-size: 1.5em;
  }

  .program-module h3 {
    font-size: 1.1em;
  }

  .details-content p, .details-content ul li {
    font-size: 0.9em;
  }
}

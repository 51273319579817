/* AllSumerizeblog.css */

.blog-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px; 
  justify-content: center;
  align-items: center;
}
.loading-container {
  text-align: center;
}
.blog-link {
  text-decoration: none;
  color: inherit;
}

.blog-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: box-shadow 0.3s ease;
}

.blog-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-thumbnail {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover; /* Fill the container without stretching */
  border-radius: 8px 8px 0 0; /* Apply border-radius to the top corners */
}

.blog-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.blog-description {
  font-size: 1rem;
  color: #666;
}

.blog-author {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #888;
}

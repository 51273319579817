.certificate-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.certificate-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #34495e;
  font-weight: 700;
}

.course-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.course-info p {
  margin: 5px 0;
  font-size: 1.2em;
}

.course-info strong {
  color: #2980b9;
}

.course-info ul {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}

.course-info ul li {
  margin-bottom: 5px;
  font-size: 1.2em;
}

.course-offerings, .syllabus, .details {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.course-offerings h2, .syllabus h2, .details h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2980b9;
  font-weight: 600;
}

.course-offerings p, .details ul li {
  font-size: 1.1em;
  line-height: 1.6;
}

.course-offerings ul, .syllabus .course ul, .details ul {
  list-style-type: none;
  padding: 0;
}

.course-offerings ul li, .syllabus .course ul li, .details ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.course-offerings ul li::before, .syllabus .course ul li::before, .details ul li::before {
  content: "✔";
  color: #27ae60;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.syllabus .course {
  margin-bottom: 30px;
}

.syllabus .course h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #2c3e50;
  font-weight: 600;
}

.details ul li::before {
  content: "•";
  color: #2980b9;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media Queries for Responsive Design */

@media (min-width: 768px) {
  .course-info {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .certificate-container h1 {
    font-size: 2em;
  }

  .course-info {
    flex-direction: column;
  }

  .course-info p, .course-info ul li, .course-offerings ul li, .syllabus .course ul li, .details ul li {
    font-size: 1em;
  }

  .course-offerings h2, .syllabus h2, .details h2 {
    font-size: 1.5em;
  }

  .syllabus .course h3 {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .certificate-container {
    padding: 10px;
  }

  .certificate-container h1 {
    font-size: 1.8em;
  }

  .course-info p, .course-info ul li, .course-offerings ul li, .syllabus .course ul li, .details ul li {
    font-size: 0.9em;
  }

  .course-offerings h2, .syllabus h2, .details h2 {
    font-size: 1.3em;
  }

  .syllabus .course h3 {
    font-size: 1em;
  }
}

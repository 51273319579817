.our__team {
  padding-top: 3rem;
}
.team__content{
  text-align: center;
  margin-bottom: 50px;
}

.team__item{
  width: 25%;
  background: var(--team-card-bg);
  border-radius: 5px;
}

.team__img{
  width: 100%;
  height: 200px;
  border-radius: 5px;
  background: white;
  overflow: hidden;
  padding-top: 10px;
}

.team__img img{
  width: 100%;
  height: 180px;
  object-fit: contain;
  transform: scale(1.3);
}

.team__details{
  padding: 20px 15px;
}

.team__details h4{
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}

h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 10px;
}
p {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 10px;
}

.team__member-social {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 10px;
}

.team__member-social span i{
  padding: 5px;
  background: var(--icon-bg);
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.team__wrapper {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

@media only screen and (max-width: 768px) {
  .team__item {
      width: 30%;
      margin-bottom: 30px;
  }

  .team__wrapper {
      flex-wrap: wrap;
  }

  .team__content h2 {
      font-size: 1.5rem;
  }

  .team__details h4 {
      font-size: 1rem;
  }

  .team__details p {
      font-size: 0.8rem;
  }
}

@media only screen and (max-width: 576px) {
  .team__item{
      width: 100%;
  }
}
/* Invoice.css */

.container {
  padding: 20px;
  max-width: 800px; /* Limit container width for better readability */
  margin: 0 auto; /* Center container horizontally */
}

.header {
  display: flex;
  margin-left: 50px;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 100px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}

.company-details {
  margin-left: 20px;
}

.company-name {
  font-size: 24px;
  margin-bottom: 5px;
}

.company-address,
.company-contact {
  font-size: 16px;
  margin: 0;
}

.invoice-content {
  padding: 20px;
  background-color: #fff; /* White background */
}

.invoice-content h2 {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-details {
  font-size: 16px;
}

.invoice-details p {
  margin: 10px 0; /* Increase margin for better spacing */
}

.invoice-details strong {
  font-weight: bold;
}

.invoice-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.invoice-actions button {
  margin-right: 10px;
}

/* Login.css */

.background-container {
  background: url('../../Assets/image.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  text-align: center;
}

.login-container h2 {
  margin-bottom: 20px;
  color: #000000; /* Changed text color to black */
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #fff; /* Changed label color to white */
}

.input-group input {
  width: 100%;
  padding: 14px; /* Increased padding for larger input fields */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button {
  width: 100%;
  padding: 14px; /* Increased padding for larger button */
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}
.input-group label{
  color: #000000;
}
.container {
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto;
}

.appointments-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.appointments-table th,
.appointments-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.appointments-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.appointments-table td {
  vertical-align: top;
}

.filter {
  margin-bottom: 10px;
}

.filter label {
  margin-right: 10px;
}

.filter select,
.filter input {
  padding: 5px;
  font-size: 14px;
}

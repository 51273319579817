.card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 3rem;
  }
  
  .card-wrapper .ant-card {
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  .card-wrapper .ant-card-body {
    display: flex;
    flex-direction: column;
  }
  
  .card-wrapper .ant-card-body .row {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .card-wrapper .ant-card-body .row .col {
    flex: 1;
  }
  
  .card-wrapper .ant-card-body .row .Doc-image-div {
    position: relative;
  }
  
  .card-wrapper .ant-card-body .row .Doc-image-div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .card-wrapper .ant-card-body .row .title {
    margin: 10px 0;
    font-size: 1.5rem;
  }
  
  .card-wrapper .ant-card-body .row .person-name p {
    margin: 5px 0;
    font-size: 1rem;
  }
  
  .card-wrapper .ant-card-body .row .information p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
  
  .card-wrapper .ant-card-body .row .button-div {
    display: flex;
    justify-content: flex-end;
  }
  
  .card-wrapper .ant-card-body .row .button-div button {
    padding: 8px 20px;
  }
  



.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.event {
  flex: 1 1 300px; /* Adjust width as needed */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px 8px 0 0; /* Rounded corners only on top */
}

.event-image:hover {
  transform: scale(1.03); /* Scale up a bit on hover for a subtle effect */
  transition: transform 0.3s ease-in-out;
}

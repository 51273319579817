.higher-diploma-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.higher-diploma-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #34495e;
  font-weight: 700;
}

.course-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.course-info .course-details {
  width: 100%;
}

.course-info p {
  margin: 5px 0;
  font-size: 1.2em;
}

.course-info strong {
  color: #2980b9;
}

.course-info ul {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}

.course-info ul li {
  margin-bottom: 5px;
  font-size: 1.2em;
}

.course-fees {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.course-fees p {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}

.course-fees ul {
  margin: 5px 0;
}

.course-fees ul li {
  margin-bottom: 5px;
  font-size: 1.2em;
}


.course-overview, .syllabus, .admission-requirements, .career-prospects {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.course-overview h2, .syllabus h2, .admission-requirements h2, .career-prospects h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2980b9;
  font-weight: 600;
}

.course-overview p, .admission-requirements ul li, .career-prospects p {
  font-size: 1.1em;
  line-height: 1.6;
}

.course-overview ul, .syllabus .module ul, .admission-requirements ul, .career-prospects ul {
  list-style-type: none;
  padding: 0;
}

.course-overview ul li, .syllabus .module ul li, .admission-requirements ul li, .career-prospects ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.course-overview ul li::before, .syllabus .module ul li::before, .admission-requirements ul li::before, .career-prospects ul li::before {
  content: "✔";
  color: #27ae60;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.syllabus .module {
  margin-bottom: 30px;
}

.syllabus .module h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #2c3e50;
  font-weight: 600;
}

/* Responsive design */
@media (max-width: 768px) {
  .higher-diploma-container {
    padding: 15px;
  }

  .higher-diploma-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .course-info p, .course-info ul li {
    font-size: 1em;
  }

  .course-overview h2, .syllabus h2, .admission-requirements h2, .career-prospects h2 {
    font-size: 1.5em;
  }

  .course-overview p, .admission-requirements ul li, .career-prospects p, .course-overview ul li, .syllabus .module ul li {
    font-size: 1em;
  }

  .syllabus .module h3 {
    font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  .higher-diploma-container {
    padding: 10px;
  }

  .higher-diploma-container h1 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .course-info {
    padding: 10px;
  }

  .course-info p, .course-info ul li {
    font-size: 0.9em;
  }

  .course-overview h2, .syllabus h2, .admission-requirements h2, .career-prospects h2 {
    font-size: 1.2em;
  }

  .course-overview p, .admission-requirements ul li, .career-prospects p, .course-overview ul li, .syllabus .module ul li {
    font-size: 0.9em;
  }

  .syllabus .module h3 {
    font-size: 1.1em;
  }
}

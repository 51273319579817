.course-details-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .course-header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #007bff;
  }
  
  .course-header h1 {
    font-size: 28px;
    color: #007bff;
    margin-bottom: 5px;
  }
  
  .course-header h3 {
    font-size: 20px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .course-header p {
    font-size: 16px;
    color: #666;
  }
  
  .course-section {
    margin-top: 25px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }
  
.courses-container  h2 {
    font-size: 22px;
    color: #0056b3;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  h3, h5 {
    color: #333;
  }
  
  p, h5 {
    font-size: 16px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    font-size: 16px;
    color: #444;
    margin: 5px 0;
    list-style-type: disc;
  }
  
  .schedule-day {
    background: #eef5ff;
    padding: 10px;
    border-left: 4px solid #007bff;
    border-radius: 6px;
    margin: 10px 0;
  }
  
  .purchase-btn {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: #007bff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-top: 20px;
  }
  
  .purchase-btn:hover {
    background: #0056b3;
  }
  
  @media (max-width: 768px) {
    .course-details-container {
      padding: 15px;
    }
  
    .course-header h1 {
      font-size: 24px;
    }
  
    .course-header h3 {
      font-size: 18px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    h3 {
      font-size: 18px;
    }
  
    p, h5, ul li {
      font-size: 14px;
    }
  
    .purchase-btn {
      font-size: 16px;
    }
  }
  
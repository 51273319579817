/* AddDoctor.css */

.containerr {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-input,
.ant-input-number {
  border-radius: 5px;
}

.ant-form-item-label {
  font-weight: bold;
}

.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-input-group-addon,
.ant-input-prefix {
  color: #333;
}

.ant-input:focus,
.ant-input-number:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-input::placeholder {
  color: #aaa;
}

.ant-input-number-handler-wrap {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  border-radius: 5px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

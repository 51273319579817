.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  ul {
    margin-top: 5px;
  }
  h1 {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .policy-section {
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-container  h2 {
    color: #0056b3;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  h3 {
    color: #333;
    margin-top: 10px;
  }
  
  p {
    margin: 8px 0;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    margin: 5px 0;
    list-style-type: disc;
  }
  
  strong {
    color: #000;
  }
  
  @media (max-width: 600px) {
    .privacy-policy-container {
      padding: 10px;
    }
    
    h1 {
      font-size: 24px;
    }
    
    h2 {
      font-size: 20px;
    }
    
    h3 {
      font-size: 18px;
    }
    
    p, ul li {
      font-size: 16px;
    }
  }
  
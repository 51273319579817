.auth-container {
  max-width: 1000px;
  margin: auto;
  padding: 20px; /* Adjust the padding for better spacing */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* border-radius: 2%; */
}

.auth-buttons {
  text-align: center; /* Center the buttons on small screens */
  margin-bottom: 20px;
}

.auth-button {
  padding: 10px 15px;
  margin: 0 5px 10px; /* Add margin to buttons for spacing */
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-button.primary {
  background-color: #3498db;
  color: #fff;
}

.auth-button.secondary {
  background-color: #f77d19;
  color: #fff;
}

.auth-button:hover {
  background-color: #2980b9;
}

.image-section {
  text-align: center;
}

.image-container {
  position: relative;
  width: 100%;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.banner-text h1 {
  font-size: 44px; /* Adjust the font size for smaller screens */
}

.banner-text p {
  font-size: 20px; /* Adjust the font size for smaller screens */
}

/* Media Query for small screens */
@media (max-width: 600px) {
  .auth-container {
    padding: 10px;
  }

  .auth-buttons {
    text-align: center;
  }

  .auth-button {
    margin: 0 5px 10px;
  }

  .banner-text h1 {
    font-size: 20px;
  }

  .banner-text p {
    font-size: 12px;
  }
}

.bold-text {
  font-weight: bold;
}

.scroll-arrow {
  text-align: center;
  font-size: 34px;
  cursor: pointer;
  margin-top: 20px;
  color: #000000; /* You can customize the color */
}


/* In your CSS file (Main.css) */
.doctors-section {
  margin-top: 20px;
  text-align: center; /* Center the text */
}

.doctor-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.doctor-card {
  max-width: 300px;
  width: 100%;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
}

.appointment-button {
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 14px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.appointment-button:hover {
  background-color: #2980b9;
}

/* Media Query for small screens */
@media (max-width: 768px) {
  .auth-buttons {
    text-align: center; /* Center the buttons on small screens */
  }

  .scroll-arrow {
    margin-top: 10px; /* Adjust spacing for the arrow on small screens */
  }

  .doctor-card {
    margin: 10px 5px; /* Adjust margin for better spacing between cards on small screens */
  }
}

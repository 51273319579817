/* Modern Course Card Styles */
.course-card {
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  position: relative;
}
.courses-container {
  width: 75%;
  margin: 0 auto; /* Centers it horizontally */
}
/* Grid Layout */
.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ensures responsive columns */
  gap: 20px;
  justify-content: center;
  align-items: start;
}
.course-details {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.course-details td {
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
}

.course-details strong {
  color: #333;
}


.course-card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

.course-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 10px;
}

.course-content {
  width: 100%;
  padding: 15px;
}

.course-title {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 8px;
}

.course-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 10px;
}

.course-info p {
  font-size: 14px;
  color: #555;
  margin: 4px 0;
}

.course-sumery {
  font-size: 14px;
  color: #777;
  margin-top: 10px;
}

.purchase-btn {
  background: linear-gradient(135deg, #4CAF50, #2E7D32);
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, transform 0.2s;
  margin-top: 12px;
}

.purchase-btn:hover {
  background: linear-gradient(135deg, #43A047, #1B5E20);
  transform: scale(1.05);
}
/* testcode */
/* Dropdown Container */
label {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: block;
  margin-bottom: 8px;
}

/* Custom Styled Select */
select {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid rgb(185, 185, 185);
  border-radius: 8px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  appearance: none; /* Removes default browser styles */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='%23007bff'><path d='M7 10l5 5 5-5H7z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 18px;
  margin-left: 0.5rem;
}

/* Hover Effect */
select:hover {
  border-color: #0056b3;
}

/* Focus Effect */
select:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Disabled Option */
option[disabled] {
  color: #aaa;
}
/* Aligns labels and input fields in a row */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Space between items */
  align-items: center;
}

/* Label styling */
.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between checkbox and text */
  font-size: 1rem;
  cursor: pointer;
}

/* Make checkboxes and radio buttons bigger */
.checkbox-group input[type="radio"],
.checkbox-group input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: #007bff; /* Custom color for selected state */
}

/* For specific radio buttons inside experience section */
.experience-res {
  font-weight: bold;
}
.agree-checkbox {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between checkbox and text */
  font-size: 1rem;
  cursor: pointer;
}

/* Make the checkbox slightly larger for better visibility */
.agree-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: #007bff; /* Custom color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .course-card {
    padding: 10px;
  }

  .course-title {
    font-size: 18px;
  }

  .purchase-btn {
    font-size: 14px;
    padding: 10px 15px;
  }
}
